<template>
  <div :class="[isMobile ? 'mobile-about-honor': '', 'about-honor']">
    <item-title v-if="!isMobile" :title="{name: '明亚荣誉', en_1: 'MINGYA', en_2: 'HONORS'}" />
    <div class="honor-content">
      <div class="honor-list">
        <div v-for="(item, index) in honorData" :key="index" class="honor-item">
          <div class="year">{{item.year}}年</div>
          <div class="year-list">
            <div v-for="val in item.list" :key="val.id" class="year-item">
              <img src="../../assets/img/aboutus/honor_item.png" alt="">
              <div class="honor-title">{{val.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ItemTitle from '@/components/aboutUs/itemTitle'
import { getEventsGroup } from '@/api/aboutUs'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    ItemTitle
  },
  data () {
    // const d = new Date()
    // let year = d.getFullYear()
    // let month = d.getMonth() + 1
    // let day = d.getDate()
    // month = month < 10 ? '0' + month : month
    // day = day < 10 ? '0' + day : day
    // let end_date = year + '-' + month + '-' + day
    return {
      isMobile: isMobile(),
      year_page: 100,
      honorData: []
    }
  },
  created () {
    this.getHonorData()
  },
  methods: {
    // 跳转详情
    jumpDetail (id) {
      this.$router.push({ path: '/aboutUs/detail', query: {type: 'honor', id: id } })
    },
    async getHonorData () {
      let res = await getEventsGroup({ year_page: this.year_page })
      res = res.filter(item => {return item.list.length > 0})
      this.honorData = this.honorData.concat(res)
      this.$emit('loading', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-about-honor.about-honor {
  margin-left: 0;
  .honor-content {
    padding-top: 0;
    .honor-list {
      .honor-item {
        margin-bottom: 15px;
        .year {
          font-size: 16px;
          color: #D4D4D4;
          font-weight: bold;
        }
        .year-list {
          border-bottom: 1px solid  #F3F3F3;
          .year-item {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 22px;
            height: auto;
            &:last-child {
              .honor-title {
                &::after {
                  display: none;
                }
              }
            }
            img {
              width: 8px;
              height: 8px;
            }
            .honor-title {
              // cursor: pointer;
              margin-left: 5px;
              flex: 1;
              position: relative;
              line-height: 38px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              padding: 80 0;
              &::after {
                display: block;
                content: "";
                width: 100%;
                height: 1px;
                background-color: #F3F3F3;
                position: absolute;
                left: 0;
                bottom: 0;
                
              }
            }
          }
        }
      }
    }
  }
}
.about-honor {  
  margin-left: 0.6rem;
  .honor-content {
    padding-top: .7rem;
    .honor-list {
      .honor-item {
        margin-bottom: .2rem;
        .year {
          font-size: .24rem;
          color: #D4D4D4;
          font-weight: bold;
        }
        .year-list {
          border-bottom: 1px solid  #F3F3F3;
          .year-item {
            display: flex;
            align-items: center;
            font-size: .16rem;
            height: .58rem;
            &:last-child {
              .honor-title {
                &::after {
                  display: none;
                }
              }
            }
            img {
              width: .18rem;
              height: .18rem;
            }
            .honor-title {
              // cursor: pointer;
              margin-left: .17rem;
              flex: 1;
              position: relative;
              line-height: .58rem;
              &::after {
                display: block;
                content: "";
                width: 100%;
                height: 1px;
                background-color: #F3F3F3;
                position: absolute;
                left: 0;
                bottom: 0;
                
              }
            }
          }
        }
      }
    }
  }
}
</style>